<script setup lang="ts">
import { formatPrice } from "~/helpers/formatters";
import { goBackCheckout } from "~/lib/routes";

const route = useRoute();

const goBack = () => {
	navigateTo(goBackCheckout(route.path));
};

const { totalPrice } = useCart();
</script>

<template>
	<div class="bg-sand min-h-screen">
		<KippieNavbar
			simple
			:search-placeholder="'Waar ben je naar op zoek?'"
			:links="[]"
			:small-links="[]"
			:cta-links="[]"
			:socials="[]"
			:payment-methods="[]"
			@close="console.log"
			@search="console.log"
		>
			<template #simple>
				<div class="flex justify-center items-center grow relative">
					<KippieIconButton icon="arrow-back" class="!absolute left-0" @click="goBack" />
					<NuxtLink to="/">
						<SvgIcon name="kippie" class="w-[90px] sm:w-[127px]" />
					</NuxtLink>
				</div>
			</template>
		</KippieNavbar>
		<div class="relative h-[60px] block lg:hidden">
			<KippieOrderDropdown class="absolute w-full">
				<template #header-label> Mijn bestelling </template>
				<template #header-price> {{ formatPrice(totalPrice) }} </template>

				<template #products>
					<ErrorMessageSection />
					<CartItemListSection read-only />
				</template>

				<template #info>
					<SummarySection />
				</template>
			</KippieOrderDropdown>
		</div>
		<slot />
	</div>
</template>
